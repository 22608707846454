import React, {useEffect, useState, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, CircularProgress, IconButton } from '@material-ui/core';
import { BackupOutlined as BackupOutlinedIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { 
  API_BASE_URL
} from "../../helpers/functions"

import { getCurrentUser } from "../Auth/AppUser"

import { Auth } from 'aws-amplify'

const useStyles = makeStyles((theme) => ({
  container:{
    marginTop: theme.spacing(3),
  },
  dropbox: {
      border: '1px dashed #CBCBCB',
      padding:theme.spacing(5),
      borderRadius:'5px',
      textAlign:'center',
      display:'flex',
      marginTop:theme.spacing(1),
      cursor:'pointer',
      [theme.breakpoints.down('sm')]: {
        padding:'24px',
      },
    },
    uploadText:{
      textAlign: 'left',
      marginLeft: '20px',
    },
    fileList:{
      padding:'0',
      marginTop: theme.spacing(3),
      listStyle:'none',
    },
    fileName:{
      padding:'15px 15px 8px 15px',
      display:'flex',
      justifyContent:'space-between',
      alignContent:'center',
      alignItems:'center',
    },
    li:{
      listStyle:'none'
    },
    FilenameLi:{
      listStyle:'none',
      margin:'0',
    }
    

}));

function Uploader(props) {

  const { field, name, handleFieldChange, uploadedFiles, userRequestId } = props  
  let gridColumn = field.display === "column" ? 6 : 12;  
  const classes = useStyles();

  const [uploadError, setUploadError] = useState('')
  const [loading, setLoading] = useState('')
  

  const uploadFile = async (file) => {

    const user = getCurrentUser();

    let auth_user_id = user['custom:portal_user_id']; 

    let formData = new FormData();
    formData.append('file',file)
    formData.append('type', field.label)
    formData.append('fileName', file.name)
    formData.append('description', file.type)
    formData.append('userId', auth_user_id) 
    formData.append('userRequestId', userRequestId)   

    const requestOptions = {
      method: 'POST',
    //  headers: { 'Content-Type': 'multipart/form-data' },
      body: formData
    };
    
    try {

        let session = await Auth.currentSession();

        //console.log(session);
      
        let idToken = session.getIdToken();
  
        let userParams = `token=${idToken.getJwtToken()}&userId=${auth_user_id}`;

        let response = await fetch(`${API_BASE_URL}UserFile/upload?${userParams}`, requestOptions)
        let data = await response.json()
        // console.log(data)
        if(data) {
          return {
            name: file.name,  
            type: file.type,
            size: file.size,
            entityId: data.entityId
          }
        }

    } catch (error) {
      setUploadError('Error in uploading your file. Please try again.')
      setLoading(false)
    }
  }

  const onDrop = useCallback( async (acceptedFiles) => {

    setLoading(true)
    const newFiles = acceptedFiles.map(async (file) => {
     
      const isExisting = uploadedFiles.filter(f => f.name === file.name); 

      if(isExisting.length == 0) {        
        const data = await uploadFile(file);
        if(data) {
          return {
              name: file.name,  
              type: file.type,
              size: file.size,
              description: field.label, 
              entityId: data.entityId
            } 
        } 
        return false            
      } else {
        return false
      }

    });

    const newAcceptedFiles = await Promise.all(newFiles);

    let newFilterdFiles = newAcceptedFiles.filter(f => f);
    // console.log(newFilterdFiles);

    if(newFilterdFiles.length > 0) {
      let updatedFilesList = [...uploadedFiles, ...newFilterdFiles]
      handleFieldChange(name, [...updatedFilesList])      
    }
    setLoading(false)

  }, [uploadedFiles])

  

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop, accept: "image/*, application/pdf, .doc, .docx"});
  
  const handleFileDelete = (file) => {
    const updatedFiles = uploadedFiles.filter(f => f.name !== file.name);
    handleFieldChange(name, [...updatedFiles])
  }
  
  // console.log(uploadedFiles)
  const files = uploadedFiles.map(f => (
    <li key={f.name} >
      <Grid>
      <Box boxShadow={2} borderRadius="10">
       <div className={classes.fileName}>
        <div>{f.name} </div>
        <IconButton aria-label="delete" onClick={() => handleFileDelete(f)}>
        <DeleteIcon />
      </IconButton>
      </div>
      </Box>
      </Grid>
    </li>
  ));

  return (
    <Grid item xs={12} sm={gridColumn} >
      <label>{field.label}</label>
      {uploadError && <div>{uploadError}</div>}
      <div {...getRootProps({className: 'dropzone'})}>
         <input {...getInputProps()} />
         <div className={classes.dropbox}>
         <BackupOutlinedIcon fontSize="large" />  
         <div className={classes.uploadText}>
           <div> <div dangerouslySetInnerHTML={{ __html: field.helpText }} />
           {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
          )}
           </div>
          </div></div>
          
       </div>
      <aside>
        <ul className={classes.fileList}>{files}</ul>
      </aside>
    </Grid>
  );
}

export default Uploader;









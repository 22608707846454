import React from "react"
import { makeStyles } from "@material-ui/core/styles";

import { 
  Grid,
  Typography,
  IconButton,
  Chip, 
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, navigate } from '@reach/router'
import Box from '@material-ui/core/Box';
import UserRequestForm from "../Forms/UserRequestForm";

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', 
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4)
    },
    input: {
        display: 'none',
      },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    li:{
      listStyle:'none',
    },
    verifiedIcon: {
      color: '#ff0000',
    },
    title:{
      marginTop: '10px',
      marginLeft: '15px',
      [theme.breakpoints.down('md')]: {
        fontSize:'1.2rem',
        marginLeft: '8px'
      },
    },
    checkLabel:{
      marginTop:theme.spacing(1.5)
    },
    alert:{
      maxWidth:'520px',
      width:'100%',
      marginTop:'20px'
    },
    container:{
      display:'unset'
    },
    headingh4:{
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
    },
    
  }));
  

export default function UserRequest({ location }) {

    if(!location || !location.state || !location.state.form) {
      navigate("/dashboard")
    }
    
  const { userRequestId, title, form, submittedInfo, formStatus, formComment, formInfo } = location.state
  //   console.log(location.state);
  //   ////////////////

//    const title = location.state.title
//    const userRequestId = location.state.userRequestId
//     let form;

//   if(location.state.form.id == '1') {

// form = {
//       "id": "1",
//       "name": "Eligibility to Work",
//       "description": "We need some documentation to verify your eligibility to work in the UK. Please note that this is a legal requirement and without it we cannot work with you.",
//       "beforeForm": "<p>We need some documentation to verify your eligibility to work in the UK. Please note that this is a legal requirement and without it we cannot work with you.</p><p>Please note that all your documentation will be stored securely by us and is only accessible by a dedicated team who are responsible for identity validation.</p>",
//       "afterForm": "",
//       "newButtonText": "Fill in",
//       "editButtonText": "Update",
//       "allowEdit": true,
//       "fields": [
//         {
//           "name": "firstname",
//           "label": "First Name",
//           "helpText": "",
//           "type": "text",
//           "required": true,
//           "display": "column",
//           "show": true
//         },
//         {
//           "name": "lastname",
//           "label": "Last Name",
//           "helpText": "",
//           "type": "text",
//           "required": true,
//           "display": "column",
//           "show": true
//         },
//         {
//           "name": "email",
//           "label": "Email Address",
//           "helpText": "",
//           "type": "email",
//           "required": true,
//           "display": "column",
//           "show": true
//         },
//         {
//           "name": "contact_number",
//           "label": "Contact Number",
//           "helpText": "",
//           "type": "text",
//           "required": true,
//           "display": "column",
//           "show": true
//         },
//         {
//           "name": "eu_eea_citizen",
//           "label": "Do you hold either a UK passport/birth certificate or an Irish passport?",
//           "helpText": "",
//           "type": "radio",
//           "required": true,
//           "options": ["Yes", "No"],
//           "display": "row",
//           "show": true,
//           "conditions": [
//             {
//               "Yes": {
//                 "fields": [
//                   {
//                     "name": "document_type",
//                     "label": "Document Type",
//                     "helpText": "",
//                     "type": "select",
//                     "required": true,
//                     "options": ["Passport", "Birth Certificate"],
//                     "display": "row",
//                     "show": true,
//                     "conditions": [
//                       {
//                         "Passport": {
//                           "fields": [
//                             {
//                               "name": "eu_passport_number",
//                               "label": "Passport Number",
//                               "helpText": "",
//                               "type": "text",
//                               "required": true,
//                               "display": "column",
//                               "show": false
//                             },
//                             {
//                               "name": "eu_pp_expiry_date",
//                               "label": "Expiry Date",
//                               "helpText": "",
//                               "type": "date",
//                               "required": true,
//                               "display": "column",
//                               "show": true
//                             },
//                             {
//                               "name": "eu_pp_attachment",
//                               "label": "Passport Copy",
//                               "helpText": "Please ensure you upload a clear photograph, or photocopy of your passport page. The image will need to show your nationality, name, date of birth, signature, photograph and MRZ code. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": true,
//                               "display": "row",
//                               "show": false
//                             }
//                           ]
//                         },
//                         "Birth Certificate": {
//                           "fields": [
//                             {
//                               "name": "birth_certificate_attachment",
//                               "label": "Birth Certificate",
//                               "helpText": "Please ensure you upload a clear photograph, or photocopy of your birth certificate. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": true,
//                               "display": "column",
//                               "show": false
//                             },
//                             {
//                               "name": "national_insurance_attachment",
//                               "label": "National Insurance",
//                               "helpText": "Please ensure you upload a clear photograph, or photocopy of your National Insurance number. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": true,
//                               "display": "column",
//                               "show": false
//                             },
//                             {
//                               "name": "photo_id_attachment",
//                               "label": "Photo ID",
//                               "helpText": "If possible please also upload photo ID (e.g. driving licence). <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": false,
//                               "display": "row",
//                               "show": false
//                             }
//                           ]
//                         }
//                       }
//                     ]
//                   }
//                 ]
//               },
//               "No": {
//                 "fields": [
//                   {
//                     "name": "eu_settled_status",
//                     "label": "Do you hold EU Settled or Pre-Settled Status under the EU Settlement Scheme (EUSS)?",
//                     "helpText": "",
//                     "type": "radio",
//                     "required": true,
//                     "options": ["Yes", "No"],
//                     "display": "row",
//                     "show": true,
//                     "conditions": [
//                       {
//                         "Yes": {
//                           "fields": [
//                             {
//                               "name": "biometric_residence_permit",
//                               "label": "Do you have a Biometric Residence Permit showing your EU Settlement Status?",
//                               "helpText": "",
//                               "type": "radio",
//                               "required": true,
//                               "options": ["Yes", "No"],
//                               "display": "row",
//                               "show": true,
//                               "conditions": [
//                                 {
//                                   "Yes": {
//                                     "fields": [
//                                       {
//                                         "name": "biometric_permit_visa_number",
//                                         "label": "Visa Number",
//                                         "helpText": "",
//                                         "type": "text",
//                                         "required": true,
//                                         "display": "column",
//                                         "show": false
//                                       },
//                                       {
//                                         "name": "biometric_permit_visa_expiry_date",
//                                         "label": "Visa Expiry Date",
//                                         "helpText": "",
//                                         "type": "date",
//                                         "required": true,
//                                         "display": "column",
//                                         "show": true
//                                       },
//                                       {
//                                         "name": "biometric_permit_visa_attachment",
//                                         "label": "Visa Copy",
//                                         "helpText": "Please ensure you upload a clear photograph, or photocopy of your visa. If you are uploading a Biometric Residence Permit please upload both the front and back of the card. If you are uploading a visa vignette from your passport please upload the accompanying passport photo page. The image will need to show your nationality, name, date of birth, signature, photograph and MRZ code. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                                         "type": "file",
//                                         "required": true,
//                                         "display": "row",
//                                         "show": false
//                                       }
//                                     ]
//                                   },
//                                   "No": {
//                                     "fields": [
//                                       {
//                                         "name": "rtw_share_code",
//                                         "label": "Right to Work Share Code",
//                                         "helpText": "<a href=\"https://www.gov.uk/prove-right-to-work\" target=\"_blank\">more info</a>",
//                                         "type": "text",
//                                         "required": true,
//                                         "display": "column",
//                                         "show": false
//                                       },
//                                       {
//                                         "name": "rtw_date_of_birth",
//                                         "label": "Your date of birth",
//                                         "helpText": "",
//                                         "type": "date",
//                                         "required": true,
//                                         "display": "column",
//                                         "show": false
//                                       }
//                                     ]
//                                   }
//                                 }
//                               ]
//                             },
//                           ]
//                         },
//                         "No": {
//                           "fields": [
//                             {
//                               "name": "non_eu_pp_number",
//                               "label": "Passport Number",
//                               "helpText": "",
//                               "type": "text",
//                               "required": true,
//                               "display": "column",
//                               "show": false
//                             },
//                             {
//                               "name": "non_eu_pp_expiry_date",
//                               "label": "Passport Expiry Date",
//                               "helpText": "",
//                               "type": "date",
//                               "required": true,
//                               "display": "column",
//                               "show": true
//                             },
//                             {
//                               "name": "non_eu_pp_attachment",
//                               "label": "Passport Copy",
//                               "helpText": "Please ensure you upload a clear photograph, or photocopy of your passport page. The image will need to show your nationality, name, date of birth, signature, photograph and MRZ code. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": true,
//                               "display": "row",
//                               "show": false
//                             },
//                             {
//                               "name": "visa",
//                               "label": "What Visa do you hold?",
//                               "helpText": "",
//                               "type": "select",
//                               "required": true,
//                               "options": [
//                                 "UK Ancestral Visa",
//                                 "Family Member EEA Dependency Visa",
//                                 "Indefinite Leave to Remain Visa",
//                                 "Spousal Visa",
//                                 "UK Tier 1 Visa",
//                                 "UK Tier 2 Visa",
//                                 "UK Tier 4 Visa",
//                                 "UK Tier 5 Visa",
//                                 "EU Settlement Visa",
//                                 "Turkish Businessperson Visa"
//                               ],
//                               "display": "row",
//                               "show": true
//                             },
//                             {
//                               "name": "visa_number",
//                               "label": "Visa Number",
//                               "helpText": "",
//                               "type": "text",
//                               "required": true,
//                               "display": "column",
//                               "show": false
//                             },
//                             {
//                               "name": "visa_expiry_date",
//                               "label": "Visa Expiry Date",
//                               "helpText": "",
//                               "type": "date",
//                               "required": true,
//                               "display": "column",
//                               "show": true
//                             },
//                             {
//                               "name": "visa_attachment",
//                               "label": "Visa Copy",
//                               "helpText": "Please ensure you upload a clear photograph, or photocopy of your visa. If you are uploading a Biometric Residence Permit please upload both the front and back of the card. If you are uploading a visa vignette from your passport please upload the accompanying passport photo page. The image will need to show your nationality, name, date of birth, signature, photograph and MRZ code. <br/><i>Only images, pdfs & documents will be accepted</i>",
//                               "type": "file",
//                               "required": true,
//                               "display": "row",
//                               "show": false
//                             }
//                           ]
//                         }
//                       }
//                     ]
//                   }
     
//                 ]
//               }
//             }
//           ]
//         }
//       ]
//     }

  // } else {
  //   form = location.state.form
  // }

  //  const submittedInfo = location.state.submittedInfo
  //  const formStatus = location.state.formStatus
  //  const formComment = location.state.formComment

///////////////////
    //console.log(location.state)
    const classes = useStyles();

    return (
        <>
        <Box display="flex">
        <Link to="/dashboard" variant="body2">
                  <IconButton aria-label="delete" className={classes.margin} size="medium">
                  <ArrowBackIcon fontSize="inherit" />
                     </IconButton>
              </Link>
         <Typography component="h1" variant="h5" className={classes.title}>
          {title}
        </Typography>
        </Box>
        <Typography component="div" >
        {formStatus === "Rejected" && formComment && 
          <div>
           <Alert className={classes.alert} variant="outlined" severity="error">{formStatus + ': ' +  formComment }</Alert>  
          </div>
        }
        </Typography>
        <Grid container spacing={2} className={classes.container}>           
        <Grid item xl={6} md={8}>
          <Typography component="h4" variant="h6" className={classes.headingh4}>
            <div dangerouslySetInnerHTML={{ __html: form.beforeForm }} />
          </Typography>
          <UserRequestForm 
            classes={classes} 
            userRequestId={userRequestId} 
            title={title} 
            form={form}
            formInfo={formInfo}
            submittedInfo={submittedInfo}
          />
        </Grid>
        </Grid>
        </>
    );
}


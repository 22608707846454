import React from 'react';
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles';

import VerifyTickImg from "../../images/verify-tick.svg"

const useStyles = makeStyles((theme) => ({
  
}));


export default function SignupVerified() {

  const classes = useStyles();
  setTimeout(() => navigate("/signin"), 2000);
  return (
    <section id="verify">
    <h1>Your account has been successfully verified now.</h1>
    <img src={VerifyTickImg} alt="" />
    <p>You will be redirected to login page shortly..</p>
    </section>
  );
}
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

/* const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:230aa987-5b91-4aed-81b0-a33afd317eaa",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_aVsDiUsjW",
    "aws_user_pools_web_client_id": "io5m5jrk5qfn8hp62bq55kjp5",
    "oauth": {}
};
 */

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5c99b20b-dd7f-4325-b06a-39a1539acf24",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_MPSKURUNy",
    "aws_user_pools_web_client_id": "5dk9ra7rtl3rm4g612p1ibhkhd",
    "oauth": {}
};


export default awsmobile;

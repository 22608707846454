import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ResetPasswordForm from '../Forms/ResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', 
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title:{
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function ForgotPassword() {

  const classes = useStyles();

  return (
    <>
        <ResetPasswordForm classes={classes} />
      </>
  );
}
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { 
    CssBaseline,
    Box,
    Container,
    Paper,
    Grid
  } from '@material-ui/core';

import Copyright from '../Partials/Copyright';
import UserLayoutHeader from '../Partials/UserLayoutHeader'

import SEO from "../seo"

const useStyles = makeStyles((theme) => ({
    breakpoints: {
        values: {
          xl: 1400,
          md: 1250,
        }
      },
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            paddingLeft: '90px',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '24px',
        },
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    dashboard: {
        [theme.breakpoints.down('sm')]: {
            padding:'0',
          },
    }
}));

export default function UserLayout({ children, location }) {
    const classes = useStyles();    
    const pageclassName = (location.pathname === '/dashboard') ? classes.dashboard : '';

    return (
      <div className={classes.root}>
          <SEO /> 
              <CssBaseline />
              <UserLayoutHeader  />
              <main className={classes.content}>
                  <div className={classes.appBarSpacer} />
                  <Container maxWidth="lg" className={`${classes.container} ${pageclassName}`}>
                      <Grid container >
                      <Grid item xs={12}>
                              <Paper className={`${classes.paper} ${pageclassName}`} >
      
      {children}</Paper>
                          </Grid>
                      </Grid>
                      <Box pt={4}>
                          <Copyright />
                      </Box>
                  </Container>
              </main>
          </div>
    )
  }

import React, { useState } from 'react';
import { navigate } from '@reach/router'
import Uploader from '../Forms/FileUploader'

import { 
    Button,
    TextField,
    Grid,
    CircularProgress,
} from '@material-ui/core';

import Alert from '../Alert'

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function DocumentUploadForm ({ classes }) {

    const [expiry, setExpiry] = useState(new Date());
    const [docnumber, setDocnumber] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handleDateChange = (date) => {
        setExpiry(date);
      };
    
    const handleChange = (event) => {
        
        if(event.target.name === 'docnumber') {
            setDocnumber(event.target.value)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        navigate("/dashboard", { state: {success: true, message: 'Document uploaded successfully' }})
        setLoading(false)
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit} >
            {error && (
                <Alert severity="error">{error}</Alert>
            )}
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="docnumber"
                label="Document Number"
                name="docnumber"
                autoFocus
                onChange={handleChange}
                value={docnumber}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className={classes.checkLabel} >
                <KeyboardDatePicker 
                    id="expiry"
                    label="Expiry Date"
                    format="MM/dd/yyyy"
                    value={expiry}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
            </Grid>
            </MuiPickersUtilsProvider>

            <Uploader />

            {/*
               TODO: FILE UPLOAD WITH DRAG & DROP.. AND TAKE PHOTO FROM MOBILE 

            <input accept="image/*" className={classes.input} id="document-upload" type="file" />
            <label htmlFor="document-upload">
                <Button variant="contained" color="primary" component="span">
                Upload
                </Button>
            </label> */}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
            >
                Save
                {loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
        </Button>


            
        </form>
    );
}

import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"

import { Typography, Grid, CircularProgress } from "@material-ui/core"

import { 
  API_BASE_URL
} from "../../helpers/functions"

import VerifyImg from "../../images/verify.svg"


import FormItem from "../Cards/FormItem"
import Alert from "../Alert"

import { getCurrentUser } from "../Auth/AppUser"
import { Auth } from 'aws-amplify'

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundImage: "linear-gradient(90deg,#D83F63 3%,#bf0a29 77%,#bf0a29 0)",
    borderRadius: "5px",
    padding: "30px 20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
    },
  },
  flexbox: {
    display: "-webkit-box",
    display: "-moz-box",
    display: "-ms-flexbox",
    display: "flex",
  },
  usernamewrap: {
    color: "#fff",
    transform: "translate(10px, 20%)",
  },
  username: {
    fontSize: "26px",
    lineHeight:'28px',
    [theme.breakpoints.down("md")]: {
      fontSize:'22px',
      lineHeight:'24px',
    }
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: "0 10px 10px 0",
  },
  gridbox: {
    marginBottom: "40px",
  },
  name: {
    marginBottom:'8px',
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom:'0px',
    }
  },
  getstarted:{
    display:'block'
  },

  checkLabel: {
    marginTop: theme.spacing(2),
	},
	root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export default function Dashboard({ location }) {

  // console.log("location obeject details: ",location)
  const classes = useStyles()
  const user = getCurrentUser();

  const message = location.state ? location.state.message : ``
  const snackbar_status = message ? true : false

  
  // console.log(user)
  let auth_user_id = user['custom:portal_user_id']; 
  const [state, setState] = useState({
              message: message, 
              snackbar_open: snackbar_status,
              currentUserId: auth_user_id,
              requests: [],
              loading: true
            })

	const[forms, setForms] = useState([])
	const[documents, setDocuments] = useState([])

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setState({
      snackbar_open: false,
      message: ``,
    })
  }

  useEffect(() => {
    fetchUserRequests()
  }, [])

  const fetchUserRequests = async () => {

    let session = await Auth.currentSession();
    
    let idToken = session.getIdToken();

    let userParams = `token=${idToken.getJwtToken()}`;

    fetch(`${API_BASE_URL}UserRequest/search?userId=${state.currentUserId}&fields=*&${userParams}`)
    .then(response => response.json())
    .then(data => {
      setState({
        requests: data,
        loading: false
      })          
    })
    .catch(error => {  
      // console.log('There was an error:' + error.message)
      setState({
        error: error.message,
        loading: false
      })
    });
  }

  return (
    <>
      {state.message && (
        <Snackbar
          open={state.snackbar_open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{state.message}</Alert>
        </Snackbar>
      )}
      <Grid container className={classes.banner}>
        <Grid item xs={8}>
          <Typography
            component="h2"
            variant="h6"
            color="textPrimary"
            className={classes.usernamewrap}
          >
            <span>Hello,</span>{" "}
            <div className={classes.username}><span>{user.name}</span> <span className={classes.getstarted}> Let’s get started!</span></div>
          </Typography>
        </Grid>
        <Grid item xs={4} align="right">
          <img src={VerifyImg} alt="" />
        </Grid>
      </Grid>
      
      {state.requests.length > 0 && 
        <>
        <div className={classes.flexbox}>
          <Typography
            component="h4"
            variant="subname1"
            color="textPrimary"
            className={classes.name}
          >
            Fill in Forms
          </Typography>
        </div>

        <Grid container spacing={2} className={classes.gridbox}>
          
            
          {state.requests.map((form, index) => (
            <FormItem form={form} key={index} />
          ))}
            
              
        </Grid>
        </>
      }

      {state.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}

      {state.loading === false && state.requests.length == 0 && 
        <div className={classes.flexbox}>
        <Typography
          component="h4"
          variant="subname1"
          color="textPrimary"
          className={classes.name}
        >
          You have no requests to complete.
        </Typography>
      </div>
      } 

    </>
  )
}

import React from "react"
import { navigate } from "@reach/router"
import { makeStyles } from "@material-ui/core/styles"
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';


import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Chip,
  Tooltip
} from "@material-ui/core"

import moment from "moment"

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  title:{
    lineHeight:'22px',
    marginBottom:'10px',
  },
  status:{
    fontSize:'14px',
    marginTop:'12px',
    display:'block',
  },
  statusReject:{
    marginTop:'6px',
    fontSize:'14px',
    marginBottom:'10px',
    display:'block'
  },
  statusLabel:{
    color:'#6d6d6d',
  },
  statusValue:{
    fontWeight:'bold',
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  statusIcon: {
    verticalAlign:'bottom',
  },

}))

const FormItem = ({ form }) => {
  const classes = useStyles()
  //console.log(form)
  const formDetails = JSON.parse(form.requestTemplate.requestTemplateDetail)
  const submittedInfo = form.requestDetail ? JSON.parse(form.requestDetail) : {}

  return (
    <Grid container item xs={12} md={4}>
      <Card className={classes.card}>
        <CardContent>
          <div>
            <Typography component="h5" variant="h6" color="textPrimary" className={classes.title}> 
              {formDetails.name}
            </Typography>

            <Typography component="p" variant="subtitle1">
            {formDetails.description}
            </Typography>

            {form.dateAdded &&
            <div>
            <span className={classes.status}>             
              <span span className={classes.statusLabel}>Requested:</span> <span className={classes.statusValue}>{moment(form.dateAdded).fromNow()}</span>
            </span>
            </div>
            }

            {form.dateLastModified && form.status === "Complete" &&
            <div>
            <span className={classes.status}>             
             <span className={classes.statusLabel}>Last Updated:</span> <span className={classes.statusValue}>{moment(form.dateLastModified).fromNow()}</span>
            </span>
            </div>
            }
             <div>
               <span className={classes.statusReject}>
                  <span className={classes.statusLabel}>Status:</span> <span className={classes.statusValue}>{form.status} </span>
               </span>
               {form.status === "Rejected" && form.comment &&                
                  // <Alert variant="outlined" severity="error" color="primary" icon={<InfoIcon />} label={form.comment} />      
                  <Alert className="alert" variant="outlined" severity="error">{form.comment}</Alert>  
               }
             </div>
          </div>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={e =>
              navigate("/user-request-form", { state: { 
                userRequestId: form.userRequestId, 
                title: formDetails.name, 
                form: formDetails,
                submittedInfo: submittedInfo,
                formStatus: form.status,
                formComment: form.comment,
                formInfo: form,
              } })
            }
          > {form.status !== "Sent" ? formDetails.editButtonText : formDetails.newButtonText}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default FormItem

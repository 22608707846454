import React from "react"
import { makeStyles } from "@material-ui/core/styles";

import { 
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from '@reach/router'
import Box from '@material-ui/core/Box';

import DocumentUploadForm from "../Forms/DocumentUploadForm";

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', 
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4)
    },
    input: {
        display: 'none',
      },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    title:{
      marginTop: '10px',
      marginLeft: '15px',
      [theme.breakpoints.down('md')]: {
        fontSize:'1.2rem',
        marginLeft: '8px'
      },
    },
    checkLabel:{
      marginTop:theme.spacing(1.5)
    }
  }));
  


export default function Documents({ location }) {

    //console.log(location)
    const classes = useStyles();

    return (
        <>
         <Box display="flex">
        <Link to="/dashboard" variant="body2">
                  <IconButton aria-label="delete" className={classes.margin} size="large">
                  <ArrowBackIcon fontSize="inherit" />
                     </IconButton>
              </Link>
         <Typography className={classes.title} component="h1" variant="h5">
          Upload Document - {location.state.title}
        </Typography>
        </Box>
        <Grid container spacing={2} >           
        <Grid item xs={12} sm={6}>
            <DocumentUploadForm classes={classes} />
        </Grid>
        </Grid>
        </>
    );
}


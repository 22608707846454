import React, { Component } from 'react';
import { navigate } from '@reach/router'
import {
  Button,
  Grid,
  CircularProgress,

} from '@material-ui/core';

import { BuildFormField, BuildConditionalFields } from "./FormElements"
import Alert from '../Alert'

import {
  API_BASE_URL
} from "../../helpers/functions"

import DateFnsUtils from '@date-io/date-fns';
import { Auth } from 'aws-amplify'

export default class UserRequestForm extends Component {

  constructor(props) {
    super(props);

    let validations = {}
    this.props.form.fields.map(field => {
      this.buildValidations(field, validations)
    })

    this.state = {
      fields: { ...this.props.submittedInfo },
      rules: { ...validations },
      error: '',
      loading: false
    }

    // console.log(this.props.form.fields);

    this.handleChange = this.handleChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.buildValidations = this.buildValidations.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);

  }

  validateForm = () => {
    let isValid = false;
    for (let [key, rule] of Object.entries(this.state.rules)) {
       //console.log(rule)
      //console.log(this.state.fields)
      const fieldValue = (key in this.state.fields) ? this.state.fields[key] : '';
      const fieldParent = (rule.parent.name && this.state.fields[rule.parent.name]) ? this.state.fields[rule.parent.name] : ''
      //console.log(key)
      //console.log(fieldValue)
      //console.log(fieldParent)
      //console.log(typeof fieldValue.value);
      let valueExists = false;
      if(fieldValue && fieldValue.value && 
        (fieldValue.value.length > 0 || 
          typeof fieldValue.value === 'boolean' || 
          (typeof fieldValue.value === 'object' && Array.isArray(fieldValue.value) === false)
          )
        ) {
        valueExists = true
        //console.log(fieldValue.value.length)
      }
      //console.log(valueExists);
      if(
          rule.required === true && 
      (
        (valueExists === false && rule.parent.name === '') || 
          (valueExists === false && rule.parent.name != '' && fieldParent.value === rule.parent.value)
      )
      ) {
        //console.log('error with ' + rule.label)
        isValid = false;
        this.setState({
          error: `${rule.label} is required`,
          loading: false
        });
        return isValid; 
      } else {
        isValid = true;
      }
    }
    
    return isValid; 

  }

  buildValidations = (field, rules, parent = {name: '', value: ''}) => {
      rules[field.name] = {
        name: field.name,
        label: field.label, 
        required: field.required,
        parent: parent,
        show: field.show
      }
      if (field.conditions) {
        const options = Object.keys(field.conditions[0])
        options.map(option => {
          field.conditions[0][option].fields.map(field2 => {
            this.buildValidations(field2, rules, { name: field.name, value: option } )         
          })
        })
      }
      return rules
  }

  handleFieldChange = (fieldName, fieldValue) => {
    // console.log(fieldValue)
    this.setState({
      fields: {
        ...this.state.fields,
        [fieldName]: {
          label: this.state.rules[fieldName].label,
          value: fieldValue,
          show: this.state.rules[fieldName].show
        }
      },
      error: '',
    })
  }

  handleChange = event => {

    let fieldValue = null

    if (!event.target) {
      fieldValue = event
    }
    else if (event.target.type === "checkbox") {
      fieldValue = event.target.checked
    }
    else {
      fieldValue = event.target.value
    }

    this.setState({
      fields: {
        ...this.state.fields,
        [event.target.name]: {
          label: this.state.rules[event.target.name].label,
          value: fieldValue,
          show: this.state.rules[event.target.name].show
        }
      },
      error: '',
    })
  }

  async handleSubmit(evt) {
    evt.preventDefault();

    try {
      this.setState({ loading: true })

      let isValid = this.validateForm();
      // console.log(isValid)
      if (!isValid) {
        this.setState({ loading: false})
        return
      }

      //console.log(this.state.fields)
      let user = await Auth.currentAuthenticatedUser();

      /* Update the user status via API */
      let session = await Auth.currentSession();

      let idToken = session.getIdToken();

      let auth_user_id = user.attributes['custom:portal_user_id']; 

      let userParams = `userId=${auth_user_id}&token=${idToken.getJwtToken()}`;

      const dateFns = new DateFnsUtils();
      let expiryDate = '';
      let submittedData = {...this.state.fields} 

      for (var key in submittedData) {

        let fieldVal = submittedData[key];
        //console.log(fieldVal);

        if(fieldVal.label.includes('registration-agreement') === true && this.props.formInfo.acceptanceType != '') {
          let acceptanceType = this.props.formInfo.acceptanceType;
          submittedData[key].label = "I accept this <a href=\"https://www.morganmckinley.com/uk/legal/registration-agreement?acceptanceType="+encodeURIComponent(acceptanceType)+"\" target=\"_blank\">Morgan Mckinley (UK) Registration agreement</a>";
        }

        if(fieldVal.value !== true && dateFns.isValid(fieldVal.value)) {
           let newDate = new Date(fieldVal.value)
          //  console.log(fieldVal.label + '--' + fieldVal.value)
          //  console.log(newDate.getTime())
          //  console.log(dateFns.isBefore(newDate, expiryDate))
          if (!dateFns.isBefore(newDate, new Date())) {
          //  console.log(newDate);
          //  console.log(new Date());
            if(!expiryDate) {
              expiryDate = newDate;
          //    console.log('The new expiry date is --' + fieldVal.value)
            } else if(dateFns.isBefore(newDate, expiryDate)){
            //  console.log(fieldVal.value);
            //  console.log('The new expiry date is --' + fieldVal.value)
              expiryDate = newDate;
            }
          }           
        }
      }
      // console.log(submittedData);
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userRequestId: this.props.userRequestId,
          requestDetail: JSON.stringify({ ...submittedData }),
          status: "Complete",
          expiryDate: expiryDate
        })
      };
      
      fetch(`${API_BASE_URL}UserRequest?${userParams}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        this.setState({ loading: false })
        navigate("/dashboard", { state: { success: true, message: `${this.props.title} form submitted successfully.` } })
      });

    } catch (error) {
      this.setState({ loading: false, error: error.message })
    }
  }


  render() {

    let { classes } = { ...this.props }
    //console.log(this.state.data)
    return (
      <>

        {this.state.error && (
          <Alert severity="error">{this.state.error}</Alert>
        )}

        <form className={classes.form} onSubmit={this.handleSubmit} >

          <Grid container spacing={2}>

            {this.props.form.fields.map((field, index) => {

              //  console.log(this.props.formInfo);

              let fieldProps = {
                field: field, 
                classes: classes,
                handleChange: this.handleChange,
                handleFieldChange: this.handleFieldChange,
                state: this.state.fields,
                userRequestId: this.props.userRequestId, 
                acceptanceType: this.props.formInfo.acceptanceType
              };

              return (
                <BuildFormField key={field.name}  {...fieldProps} />
              )
            })}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}

            >
              Submit
                {this.state.loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Button>
          </Grid>

        </form>
      </>
    )
  }
}
import React from 'react';
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { AppUser } from '../Auth'

import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText
 } from '@material-ui/core';

 import { 
    Dashboard as DashboardIcon,
    Person as PersonIcon,
    ExitToApp as ExitToAppIcon
} from '@material-ui/icons';


const { logout } = AppUser
function logOut(e) {
    e.preventDefault()

    Auth.signOut()
        .then(logout(() => navigate('/signin')))
        .catch(err => 
            console.log('error: ', err)
            )
}

const UserNav = () => {
    return (
        <List>
            <ListItem button  onClick={e => navigate('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button  onClick={e => navigate('/my-profile')}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>                        
                <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button onClick={e => logOut(e)}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Signout" />
            </ListItem>
        </List>
    );
};

export default UserNav;
import React, { useState } from "react"
import { Link } from '@reach/router'
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';

import {
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import ProfileForm from '../Forms/ProfileForm';
import UpdatePasswordForm from "../Forms/UpdatePasswordForm";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Alert from '../Alert'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  titlePassword: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    }
  },
  title: {
    marginTop: '10px',
    marginLeft: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      marginLeft: '8px'
    },
  },

}));



export default function Profile({ location }) {
  const classes = useStyles();

  const message = (location.state) ? location.state.message : ``;
  const snackbar_status = message ? true : false;

  const [state, setState] = useState({
    message: message,
    snackbar_open: snackbar_status

  })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      snackbar_open: false,
      message: ``
    });
  };

  return (
    <>
    {state.message && (
      <Snackbar  
        open={state.snackbar_open} 
        autoHideDuration={3000} 
        onClose={handleSnackbarClose}        
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
       <Alert severity="success">
         {state.message}
       </Alert>
      </Snackbar>
    )}
      <Box display="flex">
        <Link to="/dashboard" variant="body2">
          <IconButton aria-label="delete" className={classes.margin} size="large">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </Link>
        <Typography component="h1" variant="h5" className={classes.title}>
          My Profile
        </Typography>
      </Box>

      <Grid container spacing={2} >
        <Grid item xl={6} md={8}>
          <ProfileForm classes={classes} />
        </Grid>
      </Grid>

      <Grid container spacing={2} >
        <Grid item xl={6} md={8}>
          <Typography component="h1" variant="h5" className={classes.titlePassword}>
            Change Password
        </Typography>
          <UpdatePasswordForm classes={classes} />
        </Grid>
      </Grid>
    </>
  );
}


import React, { Component } from 'react';

import { 
    Typography,
    Button,
    TextField,
    Grid,
    IconButton,
    InputAdornment, 
    CircularProgress
 } from '@material-ui/core';
 
 import { Visibility, VisibilityOff, CheckCircleOutline } from '@material-ui/icons';

import Alert from '../Alert'

import { Auth } from 'aws-amplify'


import { 
    API_BASE_URL
  } from "../../helpers/functions"

import VerifyTickImg from "../../images/verify-tick.svg"

export default class RegistrationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: ``,
            lastname: ``,
            username: ``,
            password: ``,
            email: '',
            confirmemail: '',
            showPassword: false, 
            error: '',
            loading: false,
            stage: 0,
            codeRresendError: false,
            codeResendSuccess: null,
          }

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResendCode = this.handleResendCode.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    
    handleChange = event => {
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                username: event.target.value,
                error: '',
            })
        }

        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    async handleSubmit(evt) {
        evt.preventDefault();

        if(this.state.confirmemail === this.state.email) {         
        
            try {
                this.setState({ loading: true })
                const { firstname, lastname, username, email, password } = this.state;
                //console.log(this.state)

                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email: email,
                        name: firstname + ' ' + lastname,
                        given_name: firstname, 
                        family_name: lastname,
                        'custom:portal_user_id': this.props.userId.toString(),
                        'custom:first_login': '0', 
                    }
                })

                this.setState({ loading: false, stage: 1 })

                //alert("Sign up successful, check email for verification code");
            // navigate(`/signup-success`)
            } catch (error) {
                this.setState({ loading: false, error: error.message })
            }
        }
    }

    async handleResendCode(evt) {
        evt.preventDefault();        
        try {
            this.setState({ loading: true, codeResendSuccess: null })
            const username  = this.state.username;
            // console.log("state here", this.state)

            await Auth.resendSignUp(username);
            // console.log('code resent succesfully');
              this.setState({ loading: false, stage: 1,codeResendSuccess: true })
        } catch (error) {
            this.setState({ loading: false, codeRresendError: error.message, codeResendSuccess: false })
        }
    }


    render() {
        let { classes } = { ...this.props }
        if (this.state.stage === 0) {
        return (
            <>
            <Typography component="h1" variant="h5" className={classes.title}>
            Sign Up
            </Typography>
            {this.state.error && (
                <Alert severity="error">{this.state.error}</Alert>
            )}
            <form className={classes.form} onSubmit={this.handleSubmit} >
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    name="firstname"
                    autoFocus
                    onChange={this.handleChange}
                    value={this.state.firstname}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    name="lastname"
                    onChange={this.handleChange}
                    value={this.state.lastname}
                />
                </Grid>
                </Grid>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"                    
                    onChange={this.handleChange}
                    value={this.state.email}
                />
                 <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="confirmemail"
                    label="Confirm Email Address"
                    name="confirmemail"
                    autoComplete="email"                    
                    onChange={this.handleChange}
                    value={this.state.confirmemail}
                    error={(this.state.confirmemail && this.state.confirmemail !== this.state.email) ? true : false}
                    helperText={(this.state.confirmemail && this.state.confirmemail !== this.state.email) ? 'Confirm Email Address didn\'t match' : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            
                              {(this.state.confirmemail && this.state.confirmemail === this.state.email) ? <CheckCircleOutline className={classes.verifiedIcon} /> : ''}
                           
                          </InputAdornment>
                        ),
                      }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                    value={this.state.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                />               

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.loading}

                >
                    Sign Up    
                {this.state.loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </Button>
                
            </form>
            </>
        );
        }
        return (
            <section id="verify" align="center" className={classes.title}>
                <h1>We’ve sent you a <br/>Verification Email.</h1>
                <img src={VerifyTickImg} alt="" className={classes.verifyImage}/>
                <p>Click the link in your email to confirm your account. If you can’t find the email check your spam folder or click the link below to re-send.</p>
                <Button 
                    size="small" 
                    variant="outlined" 
                    color="primary" 
                    className={classes.resend}
                    disabled={this.state.loading}
                    onClick={this.handleResendCode}
                >
                        Resend Verification Email
                        {this.state.loading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                </Button>
                {this.state.codeResendSuccess === true ?
                    (<Alert severity="success">The code has been sent again successfully</Alert>)
                :  
                    this.state.codeResendSuccess === false ? 
                        (<Alert severity="error">There was an error: {this.state.codeRresendError}</Alert>)
                    : ""
                }
            </section>
        )
    }
}
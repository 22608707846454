import React from 'react'
import { Router } from '@reach/router'
import Dashboard from '../components/Pages/Dashboard'
import SignIn from '../components/Pages/SignIn'
import SignUp from '../components/Pages/SignUp'
import ForgotPassword from '../components/Pages/ForgotPassword'
import Profile from '../components/Pages/Profile'
import Documents from '../components/Pages/Documents'
import Registration from '../components/Pages/Registration'
import UserRequest from '../components/Pages/UserRequest'
import SignupVerified from '../components/Pages/SignupVerified'
import PrivateRoute from '../components/Routes/PrivateRoute'
import PublicRoute from '../components/Routes/PublicRoute'
import Amplify from 'aws-amplify'
import config from '../aws-exports'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#D83F63',
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00',
    },
    tonalOffset: 0.2, 
  },
});

const App = () => {
  Amplify.configure(config)
  //console.log('test')
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/my-profile" component={Profile} />
      <PrivateRoute path="/upload-document" component={Documents} />
      <PrivateRoute path="/registration" component={Registration} />
      <PrivateRoute path="/user-request-form" component={UserRequest} />
      <PublicRoute path="/signin" component={SignIn} />
      <PublicRoute path="/signup" component={SignUp} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />
      <PublicRoute path="/signup-verified" component={SignupVerified} />
      <PublicRoute path="/" component={SignIn} />
      
    </Router>
    </ThemeProvider>
  )
}

export default App
import React, { Component } from 'react';
import { navigate } from '@reach/router'
import { 
    Button, 
    TextField, 
    InputLabel,
    MenuItem,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Grid,
    CircularProgress,

} from '@material-ui/core';

import Alert from '../Alert'
import VerifyTickImg from "../../images/verify-tick.svg"

import { Auth } from 'aws-amplify'

export default class CandidateRegForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: ``,
            lastname: ``,
            email: '',
            contactnumber: '', 
            eu_citizen: '',
            visa: '',  
            terms: '', 
            error: '',
            loading: false,
            stage: 0,
          }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getUserProfileInfo = this.getUserProfileInfo.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        
        try {
            this.setState({ loading: true })
            navigate("/dashboard", { state: {success: true, message: 'Registration Form submitted successfully' }})
           // this.setState({ loading: false, stage: 1 })
            
        } catch (error) {
            this.setState({ loading: false, error: error.message })
        }
    }

    getUserProfileInfo() {
        //const user = getCurrentUser()
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            this.setState({
                firstname: user.attributes.given_name,
                lastname: user.attributes.family_name,
                email: user.attributes.email
            });
            //console.log(user)
        }

        )
        .catch(err => console.log(err));
    }

    componentDidMount() {
        this.getUserProfileInfo()
    }

    render() {
        let { classes } = { ...this.props }

        if (this.state.stage === 0) {

        const visaList = [
            'UK Ancestral Visa',
            'Family Member EEA Dependency Visa',
            'Indefinite Leave to Remain Visa',
            'Spousal Visa',
            'UK Tier 1 Visa',
            'UK Tier 2 Visa',
            'UK Tier 4 Visa',
            'UK Tier 5 Visa',
            'EU Settlement Visa',
            'Turkish Businessperson Visa'
        ]

        return (
            <>
            
            {this.state.error && (
                <Alert severity="error">{this.state.error}</Alert>
            )}
            <form className={classes.form} onSubmit={this.handleSubmit} >
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    name="firstname"
                    autoFocus
                    onChange={this.handleChange}
                    value={this.state.firstname}
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    name="lastname"
                    onChange={this.handleChange}
                    value={this.state.lastname}
                />
                </Grid>
                </Grid>
                <Grid item xs={12} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"                    
                    onChange={this.handleChange}
                    value={this.state.email}
                />
                </Grid>
                <Grid item xs={12} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="contactnumber"
                    label="Contact Number"
                    name="contactnumber"                  
                    onChange={this.handleChange}
                    value={this.state.contactnumber}
                />
                </Grid>
                <Grid item xs={12} className={classes.checkLabel}>
                <FormControl component="fieldset">
                <FormLabel component="legend">Are you an EU/EEA Citizen (What is this?)</FormLabel>
                <RadioGroup row aria-label="eu_citizen" name="eu_citizen" value={this.state.eu_citizen} onChange={this.handleChange}>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
                </Grid>

                { this.state.eu_citizen === 'No' &&  
                    (<Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl}>
                        <InputLabel id="visa-label">What Visa do you hold?</InputLabel>
                        <Select
                        labelId="visa-label"
                        id="visa"
                        name="visa"
                        value={this.state.visa}
                        onChange={this.handleChange}
                        >
                            {visaList.map((visa, index) => (
                                <MenuItem value={visa} key={index}>{visa}</MenuItem>
                            ))}

                        </Select>
                        </FormControl>
                    </Grid>)
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.loading}

                >
                   Submit   
                {this.state.loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </Button>
       
                
            </form>
            </>
        );
        }
        return (
            <section id="verify">
            <h1>Thank you - your registration is complete</h1>
            <img src={VerifyTickImg} />
            <p>One of our consultants will be in contact with you soon.</p>
            </section>
        )
    }
}
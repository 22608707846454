import React from 'react'
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router'
import { AppUser } from '../Auth'
import UserLayout from '../Layout/UserLayout'


const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = AppUser
    if (!isLoggedIn()) {
        return <Redirect to="/signin" noThrow />
    }
    return (
        <UserLayout location={rest.location}> 
        <Component {...rest} />
        </UserLayout>
    );

}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired
};

export default PrivateRoute;

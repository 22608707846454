import React, { useState } from 'react';
import {  
    TextField, 
    InputLabel,
    Select,
    Radio,
    Checkbox, 
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Grid,
    Typography

} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Uploader from "./FileUploader"

const BuildFormField = ( props ) => {
    const { field, classes, handleChange, handleFieldChange, state, userRequestId, acceptanceType } = props

    const fieldValue = (state[field.name]) ? state[field.name].value : ''

    let fieldElement;

    if(field.type === "text") {
        fieldElement = <TextInput value={fieldValue} name={field.name} field={field} classes={classes} handleChange={handleChange} />
    }
    if(field.type === "email") {
        fieldElement = <EmailInput value={fieldValue} name={field.name}  field={field}  classes={classes} handleChange={handleChange} />
    }

    if(field.type === "radio") {
        fieldElement = <RadioInput value={fieldValue} name={field.name}  field={field}  classes={classes} handleChange={handleChange} />
    } 

    if(field.type === "select") {
        fieldElement = <SelectBox value={fieldValue} name={field.name}  field={field}  classes={classes} handleChange={handleChange} />
    }

    if(field.type === "check") {
        fieldElement = <CheckInput value={fieldValue} name={field.name}  field={field} classes={classes} handleChange={handleChange} acceptanceType={acceptanceType} />
    }

    if(field.type === "date") {
        fieldElement = <DateInput value={fieldValue} name={field.name}  field={field} classes={classes} handleFieldChange={handleFieldChange} />
    } 

    if(field.type === "file") {
        const value = fieldValue ? fieldValue : []
        fieldElement = <Uploader uploadedFiles={value} name={field.name}  field={field} classes={classes} handleFieldChange={handleFieldChange} userRequestId={userRequestId} />
    }     
    
    if(field.type === "content") {
        let gridColumn = field.display === "column" ? 6 : 12;
        fieldElement = (
            <Grid item xs={12} sm={gridColumn}>
                <Typography component="div" >
                    <div dangerouslySetInnerHTML={{ __html: field.label }} />
                </Typography>
            </Grid>
          )
    }

    if(fieldElement) {
        return (
            <>
            {fieldElement}
            { field.conditions && <BuildConditionalFields key={field.name} {...props } />}
            </>
        )
    }
    
          
    

    return null;
};

const BuildConditionalFields = ( props ) => {
    const { field, classes, handleChange, handleFieldChange, state, userRequestId } = props

    let field_value = (state && field.name in state) ? state[field.name].value : '';
    //console.log(field_value);

    return (

       field.options.map((option, optionIndex) => {
           // console.log(field.conditions[0][option])
            return field.conditions[0][option].fields.map((field2, field2Index) => {
                //console.log(field2)
                let conditionalFieldProps = {
                    field: field2,  
                    classes: classes,
                    handleChange: handleChange,
                    handleFieldChange: handleFieldChange, 
                    state: state,
                    userRequestId: userRequestId, 
                };
                return (
                <>
                {field_value === option && <BuildFormField key={optionIndex} {...conditionalFieldProps } />}
                </>
                )

            })
        })
           
    )
}

const TextInput = ( props ) => {
    const { field, classes, value, handleChange } = props
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
    const helpText = field.helpText ? <span dangerouslySetInnerHTML={{ __html: field.helpText }} /> : '';
    return (
        <Grid item xs={12} sm={gridColumn}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required={isRequired}
                id={field.name}
                label={field.label}
                name={field.name}
                value={value}
                onChange={handleChange}
                helperText={helpText}
            />
        </Grid>
    );
};

const EmailInput = ( props ) => {
    const { field, classes, value, handleChange } = props
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
 
    return (
        <Grid item xs={12} sm={gridColumn}>
            <TextField
                variant="outlined"
                margin="normal"
                type="email"
                fullWidth
                required={isRequired}
                id={field.name}
                label={field.label}
                name={field.name}
                value={value}
                onChange={handleChange}
            />
        </Grid>
    );
};

const SelectBox = ( props ) => {
    //console.log(field)
    const { field, classes, value, handleChange } = props
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
 
    return (
        <Grid item xs={12} sm={gridColumn}>
            <FormControl required  className={classes.formControl}>
            <InputLabel htmlFor={`${field.name}-label`}>{field.label}</InputLabel>
            <Select
            native
            labelId={`${field.name}-label`}
            id={field.name}
            name={field.name}
            onChange={handleChange}
            required={isRequired}
            value={value}
            inputProps={{
                id: `${field.name}-label`,
                required: true
              }}
            >
                <option aria-label="None" value="" />
                {field.options.map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                ))}

            </Select>
            </FormControl>
        </Grid>
    );
};

const RadioInput = ( props ) => {
    const { field, classes, value, handleChange } = props
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
    const helpText = field.helpText ? <span dangerouslySetInnerHTML={{ __html: field.helpText }} /> : '';

 
    return (
        <Grid item xs={12} sm={gridColumn} className={classes.checkLabel}>
            <FormControl component="fieldset">
            <FormLabel component="legend">{field.label} {helpText}</FormLabel>
            <RadioGroup row aria-label="{`${name}-label`}" name={field.name}  onChange={handleChange}>
            {field.options.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio checked={option === value} required={isRequired} />} label={option} />
            ))}
            </RadioGroup>
            </FormControl>
        </Grid>
    );
};

const CheckInput = ( props ) => {
    const { field, classes, value, handleChange, acceptanceType } = props
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
    let labelText = field.label;
    if(acceptanceType != '') {
        labelText = "I accept this <a href=\"https://www.morganmckinley.com/uk/legal/registration-agreement?acceptanceType="+encodeURIComponent(acceptanceType)+"\" target=\"_blank\">Morgan Mckinley (UK) Registration agreement</a>";
    } 
    // console.log(value)
    return (
        <Grid item xs={12} sm={gridColumn} className={classes.checkLabel}>
            <FormControlLabel
                control={
                <Checkbox
                    onChange={handleChange}
                    name={field.name}
                    required={isRequired}
                    checked={value === true}
                />
                }
                label={ <div dangerouslySetInnerHTML={{ __html: labelText }} /> }
                
            />
        </Grid>
    );
};


const DateInput = ( props ) => {
    const { field, classes, value, handleFieldChange } = props
    //console.log(props)
    let gridColumn = field.display === "column" ? 6 : 12;
    let isRequired = field.required === true ? true : false;
    let dateValue = (value) ? value : null

    const [selectedDate, setSelectedDate] = useState(dateValue);
    
    const handleChange = date => {
       // console.log(date)
       // console.log(field.name)
        setSelectedDate(date)
       // let formattedDate = moment(date).format('DD-MM-YYYY')
        handleFieldChange(field.name, date)
    }

    return (
        <Grid item xs={12} sm={gridColumn} >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className={classes.checkLabel} >
                <KeyboardDatePicker 
                    id={field.name}
                    label={field.label}
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    required={isRequired}
                    />
            </Grid>
            </MuiPickersUtilsProvider>
        </Grid>
    );
};

export { TextInput, EmailInput, SelectBox, RadioInput, CheckInput, BuildFormField, BuildConditionalFields };
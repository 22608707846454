import React from 'react';

import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import { 
    Drawer,
    Typography,
    AppBar,
    Toolbar,
    Divider,
    Avatar,
    IconButton,
    useMediaQuery
 } from '@material-ui/core';

 import { 
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

import { getCurrentUser } from '../Auth/AppUser'

import clsx from 'clsx';

import UserNav from './UserNav';

import Logo from "../../images/logo-black.svg"

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    menuIcon: {
        color: 'black'
    },
    appBar: {
        background: '#ffff',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight:'0',
          },
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    welcome_text: {
        paddingRight: theme.spacing(2),
        color:'#000'
    }, 
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      }, 
      logo:{
        height:'45px',
        [theme.breakpoints.down('sm')]: {
            height:'36px',
          },
       
      },
      toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

const UserLayoutHeader = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const user = getCurrentUser()
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                  <Toolbar className={classes.toolbar}>
  
                      <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                      >
                          <MenuIcon className={classes.menuIcon} />
                      </IconButton>
                      <img src={Logo} alt="" className={classes.logo} />

  {/* <Typography component="span" noWrap className={classes.welcome_text}>
                          Welcome, {user.name}!
  </Typography> */}
  {/* <Avatar className={classes.orange}>{user.given_name.charAt(0)}{user.family_name.charAt(0)}</Avatar> */}
                  </Toolbar>
              </AppBar>
              <Drawer
                  variant={isMobile === true ? 'persistent' : 'permanent'}
                  classes={{
                      paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                  }}
                  open={open}
              >
                  <div className={classes.toolbarIcon}>
                      <IconButton onClick={handleDrawerClose} >
                          <ChevronLeftIcon />
                      </IconButton>
                  </div>
                  <Divider />
                  <UserNav />
              </Drawer>
              </>
    );
};

export default UserLayoutHeader;
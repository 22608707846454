import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/core/styles';
import RegistrationForm from '../Forms/RegistrationForm';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { 
  API_BASE_URL
} from "../../helpers/functions"

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title:{
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  verifyImage:{
    margin:theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      height: '120px',
    },
  },
  resend:{
    marginTop:theme.spacing(4)
  },
  alert:{
    marginTop:'200px',
    width:'300px'
  },
  loader:{
    margin:'200px auto 0 auto',
  }
}));




export default function SignUp({ location }) {

  const classes = useStyles();
  
  const [state, setState] = useState({
    stage: 0,
    userId: 0
  })
  useEffect(() => {

    let params = location.search ? queryString.parse(location.search) : {}
    // console.log(params)

    if(params.code) {
        fetch(`${API_BASE_URL}EmailRequest/search?uid=${params.code}&fields=*`)
        .then(response => response.json())
        .then(data => {
          let userInfo =data[0].user
          if(userInfo.status === "Registration") {
            setState({ stage: 1, userId: userInfo.userId })
          }
          else if(userInfo.status === "Active") {
            navigate("/signin");
          } else {
            setState({stage: 2})
          }           
        })
        .catch(error => {  
          // console.log('There was an error:' + error.message)
          setState({stage: 2})
        });
        
        /* let data = [{"userRequestId":1,"user":{"userId":2,"email":"hello@morganmckinley.com","firstName":"Second","lastName":"Test","status":"Registration","phone":"0035311111111","isYesMarketing":1,"isYesJobAlerts":0,"isDeleted":null,"dateAccessed":null,"dateAdded":"2020-04-15T08:51:51.000+0000","dateLastModified":"2020-05-05T15:46:00.000+0000"},"userRequestUid":"hsakdkasdbaskd1g32717esa","requestTypeId":"","requestDesc":"test desc","requestDetail":"test detail","documentLocation":null,"status":"Registration","isValidated":1,"isApproved":0,"dateAdded":"2020-04-21T14:57:07.000+0000","dateLastModified":"2020-05-05T15:46:05.000+0000"}]
        let userInfo =data[0].user
        if(userInfo.status === "Registration") {
          setState({ stage: 1, userId: userInfo.userId })
        } */
    } else {
      navigate("/signin");
    }
  }, [])

  if(state.stage === 0) {
    return (
      <div className={classes.loader}>
      <CircularProgress align="center"/>
    </div>
    );
  }
  
  else if(state.stage === 1 && state.userId > 0) {
    return (        
        <RegistrationForm classes={classes} userId={state.userId} />
    );
  }

  else if(state.stage === 2) {
    return (   
    <div>
      <Alert variant="filled" severity="error" className={classes.alert}>
      Invalid Request!
      </Alert>
    </div>     
      
    );
  }
}